.react-timekeeper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.react-timekeeper div {
  background-color: transparent !important;
  display: flex;
  justify-content: space-between;
}

.react-timekeeper__clock-wrapper {
  padding: 0 !important;
  width: 100% !important;
  display: block !important;
  justify-content: space-between;
}
