.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px #ffcd00 !important;
}

.rc-slider-mark-text {
  top: -50px;
  padding: 4px 8px 4px 8px;
  border-radius: 2px;
  background-color: #f1f1f1;
}
