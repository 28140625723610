@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-with-icon-yellow {
    @apply bg-palette-background-default rounded-lg w-9 h-9 flex items-center justify-center;
  }
  .card-icon-container {
    @apply w-9 h-9 p-1 rounded-lg flex justify-center items-center;
  }
  .dropdown-container {
    @apply bg-appGray/15 text-palette-text-lightDark flex items-center justify-between px-4 rounded-lg;
  }
  .customers-form-inputs {
    @apply flex justify-between gap-x-2 w-11/12 mx-auto items-center;
  }
}

input,
p,
a,
ul,
li {
  text-align: right;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad-Thin.ttf") format("truetype");
  font-weight: 100;
}

html::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}
ul::-webkit-scrollbar {
  display: none !important;
}

select::-webkit-scrollbar {
  display: none;
}

div {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  /* overflow-y: scroll; */
}

div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

* {
  font-family: Estedad, system-ui, sans-serif;
}
