.picker-input-wrapper {
  background-color: #f9fafb !important;
  border: 0px !important;
}

.icon-wrapper {
  background-color: #f9fafb !important;
}

.picker-input-fa.bg-flbite-bg.undefined {
  outline: none;
}
